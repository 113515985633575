import React from 'react';
import Backend from './Backend';
import Frontend from './Frontend';
import './skill.css';
const Skill = () => {
return (
        <section className="skill section" id="skills">
            <h2 className="section__title">My Skills</h2>
            <span className="section__subtitle">My Technical Level</span>

            <div className="skills__container container grid">
              <Frontend />
              <Backend />
            </div>  
        </section>
    );
}

export default Skill;