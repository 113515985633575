import React from 'react';
const Social = () => {

  return (
    <section className="home__social" id="home">
        <a href="https://facebook.com/mr.engineer.shuvo" className="home__social-icon" target="_blank" rel="noreferrer">
        <i className="uil uil-facebook-f"></i>
        </a>
        <a href="https://m.me/mr.engineer.shuvo" className="home__social-icon" target="_blank" rel="noreferrer">
        <i className="uil uil-facebook-messenger-alt"></i>
        </a>
        <a href="#linkedin" className="home__social-icon" target="_blank">
        <i className="uil uil-linkedin"></i>
        </a>
        <a href="#github" className="home__social-icon" target="_blank">
        <i className="uil uil-github-alt"></i>
        </a>
    </section>

  )

};

export default Social;
