import React from "react";
import "./App.css";
import About from './components/About/About';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Qualification from './components/Qualification/Qualification';
import Services from './components/Services/Services';
import Skill from './components/Skill/Skill';
import Contact from './components/Contact/Contact';
const App = () => {

  return (
    <>
      <Header />

      <main className="main">
      <Home />
      <About />
      <Skill />
      <Qualification />
      <Services />
      <Contact />
      </main>
      
    </>
  )

};

export default App;
