import React, { useState } from 'react';
import './qualification.css';
const Qualification = () => {

    const[toggleState,setToggleState]=useState(1);

    const toogleTab =(index) =>{
        setToggleState(index);
    }
    
    
return (

    
        <section className="qualification section" id="qualification">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My Personal Journey</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div className={toggleState===1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex" } onClick={()=>toogleTab(1)}>
                        <i className="uil uil-graduation-cap qualification__icon"></i> Education
                    </div>
                    <div className={toggleState===2 ? "quaqualification__titlelification__button qualification__active button--flex" : "qualification__button button--flex" } onClick={()=>toogleTab(2)}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i>Experience
                    </div>
                    <div className={toggleState===3 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex" } onClick={()=>toogleTab(3)}>
                        <i className="uil uil-meeting-board qualification__icon"></i>Training
                    </div>

                </div>
                <div className="qualification__sections">
                   <div className={toggleState===1 ? "qualification__content qualification__content-active":  "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">BSC In CSE</h3>
                                <span className="qualification__subtitle">Daffodil International University</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2014 - 2018
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                                <div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                                <div>
                                    <h3 className="qualification__title">Higher Secondary</h3>
                                    <span className="qualification__subtitle">Govt. Ainnudin College</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i>  2013
                                    </div>
                                </div>
                        </div>

                        <div className="qualification__data">
                              <div>
                                 <h3 className="qualification__title">Secondary</h3>
                                 <span className="qualification__subtitle">Nolia Shyamamohon Institution</span>
                                 <div className="qualification__calender">
                                     <i className="uil uil-calendar-alt"></i> 2011
                                 </div>
                              </div>
                              <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                              </div>
                        </div>
                    </div>
                </div>
                 <div className="qualification__sections">
                    <div  className={toggleState===2 ? "qualification__content qualification__content-active":  "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                            <div>
                                <h3 className="qualification__title">Senior Developer</h3>
                                <span className="qualification__subtitle">CRID USA (Kambaii)</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2022 - Present
                                </div>
                            </div>
                           
                        </div>

                        <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Web Developer</h3>
                                    <span className="qualification__subtitle">Dianahost Pvt Ltd</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> 2021 - 2022
                                    </div>
                                </div>
                                <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                              <div>
                                 <h3 className="qualification__title">Junior Developer </h3>
                                 <span className="qualification__subtitle">Innovadeus Pvt Ltd.</span>
                                 <div className="qualification__calender">
                                     <i className="uil uil-calendar-alt"></i> 2019 - 2021
                                 </div>
                              </div>
                        </div>
                    </div>
                </div>
                <div className="qualification__sections">
                   <div className={toggleState===3 ? "qualification__content qualification__content-active":  "qualification__content"}>

                        <div className="qualification__data">
                                <div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                                <div>
                                    <h3 className="qualification__title">PHP, Laravel</h3>
                                    <span className="qualification__subtitle">BASIS Bangladesh</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> 2017 - 2018
                                    </div>
                                </div>
                        </div>

                        <div className="qualification__data">
                              <div>
                                 <h3 className="qualification__title">React Js</h3>
                                 <span className="qualification__subtitle">Bohubrihi - Online Platform</span>
                                 <div className="qualification__calender">
                                     <i className="uil uil-calendar-alt"></i> 2022 - 2023
                                 </div>
                              </div>
                              <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                              </div>
                        </div>

                        <div className="qualification__data">
                                 <div>
                                 </div>
                                 <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                  </div>
                                  <div>
                                     <h3 className="qualification__title">Node Js</h3>
                                     <span className="qualification__subtitle">Udemy - Online Platform</span>
                                     <div className="qualification__calender">
                                         <i className="uil uil-calendar-alt"></i> 2022 - 2023
                                     </div>
                                  </div>
                        </div>
                    </div>
                </div>

            </div>  
        </section>
    );
}

export default Qualification;