import React from 'react';
const Backend = () => {
return (
    <div className="skills__content">
       <h3 className="skills__title">Backend Development Skills</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">PHP</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">Laravel</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">MySQL</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>

                    </div>
                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">REST API</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>

                    </div>
                </div>
                <div className="skills__group">
                    
                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">Node JS</h3>
                            <span className="skills__level">Basic</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">Express Js</h3>
                            <span className="skills__level">Basic</span>
                        </div>

                    </div>
                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">MongoDB</h3>
                            <span className="skills__level">Basic</span>
                        </div>

                    </div>
                    
                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>
                        
                        <div>
                            <h3 className="skills__name">Deploy</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>

                    </div>
                </div>
            </div>

       </div>
    );
}

export default Backend;