import React, { useState } from 'react';
import './services.css';

const Services = () => {

    const[toggleState,setToggleState]=useState(0);

    const toogleTab =(index) =>{
        setToggleState(index);
    }

    return(

        <section className="services section" id='service'>
             <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I Offer</span>

            <div className="services__container container grid">
                <div className="services__content">
                   <div>
                      <i className="uil uil-web-grid services__icon"></i>
                      <h3 className="services__title">Domain <br/> Hosting</h3>
                   </div>

                   <span className="services__button" onClick={()=>toogleTab(1)}>View More
                   <i className="uil uil-arrow-right services__button-icon"></i>
                   </span>

                   <div className={ toggleState===1 ? "services__modal active-modal" : "services__modal" }>
                     <div className="services__modal-content">
                        <i className="uil uil-times services__button-close" onClick={()=>toogleTab(0)}></i>

                        <h3 className="services__modal-title">Domain  Hosting</h3>
                        <p className="services__modal-description">
                        Offering reliable and efficient domain hosting services is a key aspect of my expertise. I specialize in providing secure and scalable solutions to ensure seamless website performance. From domain registration to ongoing maintenance, I prioritize the technical aspects of hosting, ensuring a stable foundation for your online presence. Let's elevate your web hosting experience for optimal efficiency and user satisfaction.
                        </p>
                        <h4 className="services__modal-service-title"> <i className="uil uil-cog services__button-icon"></i> Services:</h4>
                        <ul className="services__modal-services grid">
                            
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                              Register your company's or personal domain name.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                Select your exact hosting plan for maximum efficiency.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                              Deploy or host your project with confidence.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                              DNS management with project transfer.
                              </p>
                            </li>

                        </ul>

                     </div>

                   </div>

                </div>

                <div className="services__content">
                   <div>
                      <i className="uil uil-arrow services__icon"></i>
                      <h3 className="services__title">Mobile <br/> Application</h3>
                   </div>
                   <span className="services__button" onClick={()=>toogleTab(2)}>View More
                       <i className="uil uil-arrow-right services__button-icon"></i>
                       </span>

                       <div className={ toggleState===2 ? "services__modal active-modal" : "services__modal" }>
                     <div className="services__modal-content">
                        <i className="uil uil-times services__button-close" onClick={()=>toogleTab(0)}></i>

                        <h3 className="services__modal-title">Mobile Application</h3>
                        <p className="services__modal-description">
                        A Web Developer is in charge of ensuring websites look good and function properly. Web developers collaborate with website and graphic designers, monitor website traffic, troubleshoot website problems when they arise, and update websites as necessary.
                        </p>
                        <h4 className="services__modal-service-title"> <i className="uil uil-cog services__button-icon"></i> Services:</h4>
                        <ul className="services__modal-services grid">
                            
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                I develop user interface.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                Web page development.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                 Project deploy to server.
                              </p>
                            </li>

                        </ul>

                     </div>

                   </div>

                </div>

                <div className="services__content">
                   <div>
                      <i className="uil uil-web-grid-alt services__icon"></i>
                      <h3 className="services__title">Web <br/> Application</h3>
                   </div>

                   <span className="services__button" onClick={()=>toogleTab(3)}>View More
                       <i className="uil uil-arrow-right services__button-icon"></i>
                       </span>

                       <div className={ toggleState===3 ? "services__modal active-modal" : "services__modal" }>
                     <div className="services__modal-content">
                        <i className="uil uil-times services__button-close" onClick={()=>toogleTab(0)}></i>

                        <h3 className="services__modal-title">Web Application</h3>
                        <p className="services__modal-description">
                        A Web Developer is in charge of ensuring websites look good and function properly. Web developers collaborate with website and graphic designers, monitor website traffic, troubleshoot website problems when they arise, and update websites as necessary.
                        </p>
                        <h4 className="services__modal-service-title"> <i className="uil uil-cog services__button-icon"></i> Services:</h4>
                        <ul className="services__modal-services grid">
                            
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                I develop user interface.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                Web page development.
                              </p>
                            </li>
                            <li className="services__modal-service">
                              <i className="uil uil-check-circle services__modal-icon"></i>
                              <p className="services__modal-info">
                                 Project deploy to server.
                              </p>
                            </li>

                        </ul>

                     </div>

                   </div>

                </div>
            </div> 
        </section>
    );   

}

export default Services;